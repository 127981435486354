$(function() {
  // console.clear();
  console.log('Container Equalization');
  let outers = document.querySelectorAll('.design_wrapper');//
  console.log(outers);

  function indexResizer () {
    let containerHeights = [], h2Heights = [], imageHeights = [];

    outers.forEach(function (item) {
      containerHeights.push(item.clientHeight);
      h2Heights.push(item.firstElementChild.clientHeight - item.firstElementChild.firstElementChild.firstElementChild.clientHeight);
      imageHeights.push(item.firstElementChild.firstElementChild.firstElementChild.clientHeight);
    }); //outers.forEach(function(item) { END
    console.log('containerHeights: ' + containerHeights);
    console.log('h2Heights: ' + h2Heights);
    console.log('imageHeights: ' + imageHeights);

    let containerMax = Math.max.apply(null, containerHeights);
    let h2Max = Math.max.apply(null, h2Heights);
    let imageMax = Math.max.apply(null, imageHeights);

    let contMaxIndex = containerHeights.indexOf(containerMax);
    let h2MaxIndex = h2Heights.indexOf(h2Max);
    let imageMaxIndex = imageHeights.indexOf(imageMax);

    console.log('containerMax: ' + containerMax);
    console.log('containerMax Index: ' + contMaxIndex);
    console.log('h2Max: ' + h2Max);
    console.log('h2Max Index: ' + h2MaxIndex);
    console.log('imageMax: ' + imageMax);
    console.log('imageMax Index: ' + imageMaxIndex);
    //Simon: Note: Above is working fine.

    //Simon: Fix Me: Below is a pain, may need to restructure html a little
    let padList = [];
    outers.forEach(function (item) {
      let containerHeight = item.clientHeight;
      let h2Height = item.clientHeight - item.firstElementChild.clientHeight;
      let imageHeight = item.firstElementChild.firstElementChild.firstElementChild.clientHeight;
      let imageDif = imageMax - imageHeight;
      let h2Dif = h2Max - (item.firstElementChild.clientHeight - item.firstElementChild.firstElementChild.firstElementChild.clientHeight);
        let reqPadding = imageDif/2;
        padList.push(reqPadding);
        item.firstElementChild.firstElementChild.firstElementChild.setAttribute(
            'style',
            `margin-top: ${reqPadding}px; margin-bottom: ${reqPadding}px;`
        );

      // console.log(imageHeight);
      // let h2Height = item.clientHeight - item.firstElementChild.firstElementChild.firstElementChild.clientHeight;
      // console.log(h2Height);




      imageHeights = [];
      h2Heights = [];
      // containerHeights = []; //padList = [];
    });
    console.log('padList: ' + padList);
  } //function indexResizer () { END
  indexResizer();
  // document.addEventListener("load", indexResizer);
  window.addEventListener("resize", indexResizer);

  let a = [1, 4, 3, 2], //would be h2 container
      b = [0, 2, 1, 2]; //would be image
  let x = a.map(function(value, key) { //would be required spacing
    return value - b[key];
  });
  console.log(x);

}); //$(function() { END